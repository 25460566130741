import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';



const Table4=(props)=>{
  return <div className={"table-responsive "+props.bg}>
            <table className="table">
              <thead className={props.desing}>
                <th>Zona</th>
              </thead>
              <tbody>
                {props.data.map((row,key)=>{
                  return  <tr key={key}>
                            <td>{row.screen_string}</td>
                          </tr>
                })}
              </tbody>
            </table>
          </div>
}

const Table3=(props)=>{
  return <div className={"table-responsive "+props.bg}>
            <table className="table">
              <thead className={props.desing}>
                <th>{props.title}</th>
                <th className="text-center">Cantidad</th>
              </thead>
              <tbody>
                {props.data.map((row,key)=>{
                  return  <tr key={key}>
                            <td>{row[0]}</td>
                            <td className="text-center text-danger cursor-pointer" onClick={()=>props.openZona(row[1])}>
                              {row[1].length}
                            </td>
                          </tr>
                })}
              </tbody>
            </table>
          </div>
}

const Table1=(props)=>{
  return <div className={"table-responsive "+props.bg}>
            <table className="table">
              <thead className={props.desing}>
                <th>{props.title}</th>
                <th className="text-center">Cantidad</th>
              </thead>
              <tbody>
                {Object.entries(props.data.nuevo_resumen).map((row,key)=>{
                  let row_ =  Object.entries(row[1])
                  return  <tr key={key}>
                            <td>{row[0]}</td>
                            <td className="text-center text-danger">
                              <div onClick={()=>{props.setVerSubcategoria(row_);props.openZona(false)}} className="cursor-pointer">
                                {row_.length}
                              </div>
                            </td>
                          </tr>
                })}
              </tbody>
            </table>
          </div>
}

const Table2=(props)=>{
  return <div className={"table-responsive "+props.bg}>
            <table className="table">
              <thead className={props.desing}>
                <th>Acción</th>
                <th>Subacción</th>
                <th>Zona</th>
                <th>Tiempo</th>
              </thead>
              <tbody>
                {props.data.map((row,key)=>{
                  return  <tr key={key}>
                            <td>{row.categoria}</td>
                            <td>{row.subcategoria}</td>
                            <td>{row.screen_string}</td>
                            <td>{row.tiempo}</td>
                          </tr>
                })}
              </tbody>
            </table>
          </div>
}

const App=(props)=>{
  const context = React.useContext(StateContext);
  const [data, setData] = useState({resumen:{}});
  const [minutoAMinuto, setMinutoAMinuto] = useState(false);
  const [verSubcategoria, setVerSubcategoria] = useState(false);
  const [verZonas, setZonas] = useState(false);
  const [openZona, setOpenZona] = useState(false);


  function getInit(){
    let data        = {}
        data.token  = Store.get("user").token
        data.id     = props.id
    Functions.PostAsync("Tags","getCronometroResumenEstadistico",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    setData(data.response.data)
  }

  useEffect(() => {
    getInit()
  },[props.show.open]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="xl"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    {props.show.title}
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="btn btn-blcok btn-primary" onClick={()=>{setMinutoAMinuto((!minutoAMinuto?true:false))}}>
                      Minuto a minuto ({!minutoAMinuto?"Off":'On'})
                    </div>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={!minutoAMinuto?"row":'d-none'}>
                <div className="col-12 col-sm-4 mb-3">
                  <div className="over-flow-y">
                    <Table1 openZona={setOpenZona} setVerSubcategoria={setVerSubcategoria}  setZonas={setZonas} data={data} bg={"bg-light"} desing={"thead-dark"} title={"Por categoría"}/>
                  </div>
                </div>
                {verSubcategoria?<>
                  <div className="col-12 col-sm-4 mb-3">
                    <div className="over-flow-y">
                      <Table3 openZona={setOpenZona} data={verSubcategoria} bg={""} desing={"thead-danger"} title={"Por subcategoría"}/>
                    </div>
                  </div>
                  {openZona?<>
                    <div className="col-12 col-sm-4 mb-3">
                      <div className="over-flow-y">
                        <Table4 data={openZona} bg={"bg-light"} desing={"thead-dark"} title={"Por zona"}/>
                      </div>
                    </div>
                  </>:false}
                </>:false}
              </div>
              <div className={minutoAMinuto?"row":'d-none'}>
                <div className="col">
                  <div className="over-flow-y">
                    <Table2 data={data.all} bg={"bg-light"} desing={"thead-dark"} title={"Por zona"}/>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
}

export default App;
