import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import StateContext from '../helpers/ContextState';
import Store from "../helpers/Store";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{

  const context = React.useContext(StateContext);
  const [data, setData] = useState([]);

  function getInit(){
    let data              =   {tabla:props.tabla}
        data.token        =   Store.get("user").token
        data.op_macrocalendario_estructura_id        =   props.show.id
    Functions.PostAsync("Cronograma","GetCronogramaResumenCalendario",data,context,{name:"callbackInit",funct:callbackInit})
  }

  function callbackInit(data){
    setData(data.response.data)
  }

  useEffect(() => {
    getInit()
  },[props.show.open]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0})}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {data.map((row,key)=>{
                return <div key={key} className="mb-2 p-1" style={{backgroundColor:row.background,color:row.color}}>
                  {row.label} {row.tipo_competencia} {row.fecha_desde_format} al {row.fecha_hasta_format}
                  {row.ma_estados_macrocalendario_id==="2" || row.ma_estados_macrocalendario_id==="5"?<div className="text-right "><a className="text-white" href={context.Config.ConfigAppUrl+"cronogramas/cronograma/microCalendario?id="+row.op_macrocalendario_estructura_calendario_id+"&id2="+row.op_macrocalendario_estructura_id+"&id3="+row.op_macrocalendario_id}><FontAwesomeIcon icon={faSearch}/></a></div>:<></>}
                </div>
              })}
            </Modal.Body>
          </Modal>
}

export default App;
