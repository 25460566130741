import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';


const App=(props)=>{
  const context = React.useContext(StateContext);
  const [data, setData] = useState([]);

  function getInit(){
    let data        = {}
        data.token  = Store.get("user").token
        data.id     = props.id

    Functions.PostAsync("Tags","getCronometroResumen",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    setData(data.response.cronometro)
  }

  useEffect(() => {
    getInit()
  },[props.show.open]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="md"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="table">
                <thead>
                  <th>Desde</th>
                  <th>Hasta</th>
                  <th>Acumulado</th>
                </thead>
                <tbody>
                {data.map((row,key)=>{
                  //console.log(row)
                  return  <tr>
                            <td>
                              {row.hora_inicio}
                            </td>
                            <td>
                              {row.hora_final}
                            </td>
                            <td>
                              {row.cronometro}
                            </td>
                          </tr>
                })}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>
}

export default App;
