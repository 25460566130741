import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';

const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({label:"",token:"",tabla:""});

  function onSubmit(e){
    e.preventDefault()
    if (inputs.title==='Sin título definido' || inputs.body==='Sin mensaje definido') {
      return console.log(inputs);
    }
    let data          = inputs
        data.title    = inputs.title
        data.body     = inputs.body
        data.token    = Store.get("user").token
    Functions.PostAsync("Notificaciones","SetNotificaciones",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    if (data.response.close) {
      props.setShow({open:false,id:0,})
      props.getInit()
    }
  }

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  useEffect(() => {
    setInputs({
                id:props.show.id,
                title:"Sin título definido",
                body:"Sin mensaje definido",
              })
  },[props.show.open]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0})}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit}>
                <div>
                  <input onChange={onChange} name="title" className="form-control mb-3" type="text" placeholder={'Título...'} require/>
                </div>
                <div>
                  <input onChange={onChange} name="body" className="form-control" type="text" placeholder={'Mensaje...'} require/>
                </div>
                <div className="mt-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
