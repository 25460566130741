import React,{useState,useEffect} from 'react';
import StateContext from '../../helpers/ContextState'
import Functions from "../../helpers/Functions";
import Logo from '../../assets/images/design/logo.png';

const sexo=[{label:"Masculino",value:"Masculino"},{label:"Femenino",value:"Femenino"}]
const App=(props)=>{

  const context = React.useContext(StateContext);

  const [inputs, setInputs] = useState({login:"",password:""});
  const [open, setOpen] = useState(false);
  const [tipo_usuario_id, setTipo_usuario_id] = useState([]);

  useEffect(() => {
    getInit()
  },[]);

  function getInit(){
    let data        = {}
        data.token  = Functions.segment()
    Functions.PostAsync("Maestros","getTiposUsuarios",data,context,{name:"callbackGetInit",funct:callbackGetInit})
  }

  function callbackGetInit(data){
    setTipo_usuario_id(data.response.tipo_usuario_id)
  }

  function setInput(e){
    let input = inputs
        input[e.target.name]  = e.target.value
        setInputs(input)
  }

  function onSubmit(e){
    e.preventDefault()
    let data  = inputs
    Functions.PostAsync("User","registerUserCode",data,context,{name:"callbackContinue",funct:callbackContinue})
  }

  function callbackContinue(data){
    if (data.code===403) {
      context.setAlert({show:true,message:data.response.message})
    }else {
      if (data.response.data!==undefined) {
        setInputs(data.response.data);
        setOpen(true);
      }
    }
  }

  function onChange(e){
    let input                = {...inputs}
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  return  <div className="container auth">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-6 mt-3">
                <form onSubmit={onSubmit}>
                  <div className="card">
                    <div className="card-body">
                      {!open?<><div className="row mb-3">
                        <div className="col">
                          <input  className="form-control"
                                  name="codigo_invitacion"
                                  onChange={setInput}
                                  placeholder="Código invitacion"
                                  required
                          />
                        </div>
                      </div>
                      <div className="row mb-3 text-center">
                        <div className="col">
                          <button className="btn btn-primary" type="submit">
                            Registrar
                          </button>
                          <a href={context.Config.ConfigAppUrl+context.Constants.Modulos.auth.login.url} className="btn btn-link">Cancelar</a>
                        </div>
                      </div></>:<>
                      <input onChange={onChange} name="usuario_id" className="form-control" type="hidden" defaultValue={inputs && inputs.usuario_id?inputs.usuario_id:''}/>
                      <div className="row mb-3">
                        <div className="col-12  col-sm-4 mb-3">
                          <input onChange={onChange} name="nombres" className="form-control" type="text" placeholder={'Nombres...'} defaultValue={inputs && inputs.nombres?inputs.nombres:''} require/>
                        </div>
                        <div className="col-12  col-sm-4 mb-3">
                          <input onChange={onChange} name="apellidos" className="form-control" type="text" placeholder={'Apellidos...'} defaultValue={inputs && inputs.apellidos?inputs.apellidos:''} require/>
                        </div>
                        <div className="col-12  col-sm-4 mb-3">
                          <input onChange={onChange} name="email" className="form-control" type="text" placeholder={'Email...'} defaultValue={inputs && inputs.email?inputs.email:''} require/>
                        </div>
                        <div className="col-12  col-sm-12 mb-3">
                          <input onChange={onChange} name="direccion" className="form-control" type="text" placeholder={'Dirección completa (Ciudad y Departamento)'} defaultValue={inputs && inputs.direccion?inputs.direccion:''}/>
                        </div>
                        <div className="col-12  col-sm-3 mb-3">
                          <input onChange={onChange} name="cedula" className="form-control" type="text" placeholder={'Identificación'} defaultValue={inputs && inputs.cedula?inputs.cedula:''}/>
                        </div>
                        <div className="col-12  col-sm-3 mb-3">
                          <select name="sexo" className="form-control" onChange={onChange} required defaultValue={inputs && inputs.sexo?inputs.sexo:''}>
                            <option value="">Género</option>
                            {
                              sexo.map((row,key)=>{
                                return <option key={key} value={row.value}>{row.label}</option>
                              })
                            }
                          </select>
                        </div>
                        <div className="col-12  col-sm-3 mb-3">
                          <input onChange={onChange} name="telefono_fijo" className="form-control" type="text" placeholder={'Teléfono fijo'} defaultValue={inputs && inputs.telefono_fijo?inputs.telefono_fijo:''}/>
                        </div>
                        <div className="col-12  col-sm-3 mb-3">
                          <input onChange={onChange} name="celular" className="form-control" type="text" placeholder={'Celular'} defaultValue={inputs && inputs.celular?inputs.celular:''}/>
                        </div>
                        <div className="col-12  col-sm-4 mb-3">
                          <input onChange={onChange} name="fecha_nacimiento" className="form-control" type="date" placeholder={'Fecha de nacimiento'} defaultValue={inputs && inputs.fecha_nacimiento?inputs.fecha_nacimiento:''}/>
                        </div>
                        <div className="col-12  col-sm-4 mb-3">
                          <input onChange={onChange} name="posicion" className="form-control" type="text" placeholder={'Posición'} defaultValue={inputs && inputs.posicion?inputs.posicion:''}/>
                        </div>
                        <div className="col-12  col-sm-4 mb-3">
                          <input onChange={onChange} name="perfil" className="form-control" type="text" placeholder={'Perfil'} defaultValue={inputs && inputs.perfil?inputs.perfil:''}/>
                        </div>
                        <div className="col-12  col-sm-4 mb-3">
                          <input onChange={onChange} name="password" className="form-control" type="password" placeholder={'password'} defaultValue={inputs && inputs.password?inputs.password:''}/>
                        </div>
                      </div>
                      <div className="row mb-3 text-center">
                        <div className="col">
                          <button className="btn btn-primary" type="submit">
                            Registrar
                          </button>
                          <a href={context.Config.ConfigAppUrl+context.Constants.Modulos.auth.login.url} className="btn btn-link">Cancelar</a>
                        </div>
                      </div>
                      </>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
}

export default App;
