import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import StateContext from '../helpers/ContextState';

const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({label:"",token:"",tabla:""});

  function onSubmit(e){
    e.preventDefault()
    let data  = inputs
    Functions.PostAsync(props.modulo,props.metodo+"Add",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    if (data.response.close) {
      props.setShow({open:false,id:0,})
      props.getInit()
    }
  }

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  useEffect(() => {
    setInputs({
                id:props.show.id,
                label:props.show.label,
                tabla:props.tabla
              })
  },[props.show.open]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0})}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit}>
                <div>
                  <input onChange={onChange} name="label" className="form-control" type="text" placeholder={props.show.title+'...'} defaultValue={props.show.label} require/>
                </div>
                <div className="mt-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
