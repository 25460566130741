import React from 'react';
import Gradas from '../../assets/images/design/BC-Portada.jpg';
import Store from '../../helpers/Store'
import Config from '../../helpers/Config'
import { faSearch,faChalkboardTeacher,faVolleyballBall,faCalendarAlt,faCalendarCheck,faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StateContext from '../../helpers/ContextState';

const App=(props)=>{

  const context =   React.useContext(StateContext);
  let items     =   context.Constants.Modulos.usuarios.items;
  return  <>
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <h1 className="text-success m-0">Bienvenido</h1>
                  <h3 className="text-dark">{Store.get("user").login}</h3>
                </div>
                <div className="col-12 col-sm-6 pt-4">
                  <div className="input-group mb-3 input-group-lg">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <FontAwesomeIcon className="text-white" icon={faSearch}/>
                      </span>
                    </div>
                    <input  className="form-control"
                            name="login"
                            placeholder="Buscar..."
                            required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid bg-parte-equipo mt-3" style={{backgroundImage:"url("+Gradas+")", backgroundPosition: 'center'}}>
              <div className="container">
                <div className="row pt-5">
                  <div className="col mt-5 big-title text-right">
                    <h1 className="m-0">QUE ESPERAS</h1>
                    <h2 className="m-0">PARA SER PARTE</h2>
                    <h1 className="m-0">DEL EQUIPO</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mb-5">
                  {parseInt(Store.get("user").tipo_usuario_id)<=3 || parseInt(Store.get("user").tipo_usuario_id)===23?<>
              <div className="row pt-5 text-center justify-content-center">
                <div className="col mt-5">
                  <div className="card">
                    <a className="m-0" href={Config.ConfigAppUrl+items[2].Entrenador.url}>
                      <div className="card-body bg-success">
                        <FontAwesomeIcon className="text-white h1" icon={faChalkboardTeacher}/>
                      </div>
                    </a>
                    <a className="m-0 h6 mb-3 mt-3" href={Config.ConfigAppUrl+items[2].Entrenador.url}>Entrenador</a>
                  </div>
                </div>
                <div className="col-12 col-sm-4 mt-5">
                  <div className="card">
                    <a className="m-0" href={Config.ConfigAppUrl+items[1].Jugadores.url}>
                      <div className="card-body bg-success">
                        <FontAwesomeIcon className="text-white h1" icon={faVolleyballBall}/>
                      </div>
                    </a>
                    <a className="m-0 h6 mb-3 mt-3" href={Config.ConfigAppUrl+items[1].Jugadores.url}>Jugadores</a>
                  </div>
                </div>
                <div className="col-12 col-sm-4 mt-5">
                  <div className="card">
                    <a className="m-0" href={Config.ConfigAppUrl+"cronogramas/cronograma"}>
                      <div className="card-body bg-success">
                        <FontAwesomeIcon className="text-white h1" icon={faCalendarAlt}/>
                      </div>
                    </a>
                    <a className="m-0 h6 mb-3 mt-3" href={Config.ConfigAppUrl+"cronogramas/cronograma"}>Macrocalendarios</a>
                  </div>
                </div>
                <div className="col-12 col-sm-4 mt-5">
                  <div className="card">
                    <a className="m-0" href={Config.ConfigAppUrl+"cronogramas/reporte_entrenamientos"}>
                      <div className="card-body bg-success">
                        <FontAwesomeIcon className="text-white h1" icon={faCalendarCheck}/>
                      </div>
                    </a>
                    <a className="m-0 h6 mb-3 mt-3" href={Config.ConfigAppUrl+"cronogramas/reporte_entrenamientos"}>Reportes</a>
                  </div>
                </div>
                <div className="col-12 col-sm-4 mt-5">
                  <div className="card">
                    <a className="m-0" href={Config.ConfigAppUrl+"tags/listado"}>
                      <div className="card-body bg-success">
                        <FontAwesomeIcon className="text-white h1" icon={faUsers}/>
                      </div>
                    </a>
                    <a className="m-0 h6 mb-3 mt-3" href={Config.ConfigAppUrl+"tags/listado"}>Convocatorias</a>
                  </div>
                </div>
              </div>
                  </>:false}
            </div>
            <div className="container-fluid bg-parte-equipo mt-3" style={{backgroundImage:"url("+Gradas+")", backgroundPosition: 'center'}}>
              <div className="container">
                <div className="row pt-5">
                  <div className="col mt-5 big-title text-center">
                    <a className="btn btn-primary btn-lg mt-5 text-white" href="https://laf.com.co/noticias/categoria/seleccion-antioquia" target="_blank">
                      Ver más
                    </a>
                  </div>
                  <div className="col mt-5 big-title2 text-right">
                    <h1 className="m-0">Conoce sobre</h1>
                    <h2 className="m-0">el mundo del fútbol</h2>
                  </div>
                </div>
              </div>
            </div>
          </>
}

export default App;
