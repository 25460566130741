import React,{useEffect,useState} from 'react';
import { BrowserRouter, Route, Switch  } from "react-router-dom";
import './App.css';
import Config from "./helpers/Config";
import Store from './helpers/Store'
import Constants from './helpers/Constants'
import Functions from './helpers/Functions'
import StateContext from './helpers/ContextState';
import Auth from './app/auth';
import Dashboard from './app/dashboard';
import Alert from './screens/alert';
import Menu from './screens/menu';
import Footer from './screens/footer';
import MenuUsuariosComunes from './screens/menuUsuariosComunes';
import Configuracion from './app/configuracion';
import TipoUsuarios from './app/configuracion/tipoUsuarios';
import Usuarios from './app/usuarios';
import CambioUsuarios from './app/usuarios/cambio_usuarios';
import RegistroConvocatorias from './app/convocatoria';
import RegistroCronograma from './app/cronograma';
import RegistroCronogramaPasoDos from './app/cronograma/registroCronogramaPasoDos';
import RegistroMicroCronograma from './app/cronograma/registroMicroCronograma';
import RegistroMicroCronogramaEntrenador from './app/cronograma/registroMicroCronogramaEntrenador';
import RegistroMicroEncuestas from './app/cronograma/registroMicroEncuestas';
import RegistrarEquipos from './app/configuracion/registrarEquipos';
import ReportesRPE from './app/reportes/rpe';
import Antropometria from './app/reportes/antropometria';
import HelperCopy from './app/testingHelper/copy';
import Tags from './app/tags/index';
import TagsOpenFullScreen from './app/tags/tagsOpenFullScreen';
import GaleriaFotosVideos from './app/cronograma/galeriaFotosVideos';
import Notificaciones from './app/cronograma/notificaciones';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Logo from './assets/images/design/favicon.png'
import LogoFenix from './assets/images/design/fenix.jpg'
import LogoNotificacion from './assets/images/design/logo-notificacion.png'
import IcoNotificacion from './assets/images/design/ico-notificacion.png'

import {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription
} from "./notificaciones/push-notifications";
/*npm run build*/

const pushNotificationSupported = isPushNotificationSupported();

let serviceWorker_

const App=(props)=>{

  const [exception, setException]                         =   useState(false);
  const [alert, setAlert]                                 =   useState({show:false,message:""});
  const [user, setUser]                                   =   useState({});
  const [showInstalledMessage, setShowInstalledMessage]   =   useState(false);
  const [showUpdateMessage, setshowUpdateMessage]         =   useState(false);
  const [userConsent, setSuserConsent]                              =   useState(Notification.permission);
  const [btnPreguntarNotificaciones, setBtnPreguntarNotificaciones] =   useState(false);
  const [activarMenu, setActivarMenu]  =   useState(false);
                                                                                                                                            useEffect(() => {
  if (pushNotificationSupported) {
    askUserPermission().then(consent => {
      setSuserConsent(consent);
      if (consent !== "granted") {
        setBtnPreguntarNotificaciones(true)
      }else if (consent === "granted" || consent ==="denied") {
        setBtnPreguntarNotificaciones(false)
      }
    })
    serviceWorkerRegistration.register();
  }
  },[])

  function preguntarNotificaciones(){
    if (pushNotificationSupported) {
      askUserPermission().then(consent => {
        setSuserConsent(consent);
        if (consent === "granted" || consent ==="denied") {
          setBtnPreguntarNotificaciones(false)
        }
      })
    }
  }

  useEffect(() => {
    if (  Store.get("user").token===undefined &&
          ( window.location.pathname!=="/"+Constants.Modulos.auth.login.url  &&
            window.location.pathname!=="/"+Constants.Modulos.auth.recover.url  &&
            window.location.pathname!=="/"+Constants.Modulos.auth.recoverPass.url  &&
            window.location.pathname!=="/"+Constants.Modulos.auth.register.url &&
              window.location.pathname!=="/"+Constants.Modulos.auth.luisadelgado.url &&
            !window.location.pathname.includes(Constants.Modulos.auth.recoverPass.url)
          )
        ) {
      document.location.href = Constants.Modulos.auth.login.url
    }else if(window.location.pathname==="/Cerrar"){
      Store.clear()
      Store.set("privilegios","")
      document.location.href = Constants.Modulos.auth.login.url
    }else {
      setActivarMenu(true)
      setUser(Store.get("user"))
    }
    //notificacion()
  },[]);

  function _setState(){

  }

  function seleccionMenu(tipo){
    /*EXCEPCIONES DE MENU*/
    switch (window.location.pathname) {
      case "/tags/tag/open":
        return false
      break;
      default:

      break;
    }
    if (Store.get("user").tipo_usuario_id<2) {
      if (tipo==='menu') {
        return <Menu />
      }else{
        return <Footer />
      }
    }else {
      if (tipo==='menu') {
        return <MenuUsuariosComunes />
      }else{
        return <Footer />
      }
    }
  }

  /*SISTEMA DE NOTIFICACIONES*/

  function get_notificacion(data_){
    let context     = { Constants:Constants,
                                            Store:Store,
                                            Config:Config,
                                            setState:_setState,
                                            alert:alert,
                                            setAlert:setAlert,
                                            user:user,
                                            setUser:setUser}
    let data        = {tabla:props.tabla}
        data.token  = Store.get("user").token
        Functions.PostAsync("Notificaciones","getMisnotificaciones",data,context,{name:"printNotificaciones",funct:printNotificaciones})
    //console.log(data);
    // Notification.requestPermission().then(function(result) {
    //   if (data.options===undefined) {
    //     return false;
    //   }
    //   if  (Notification.permission  ===  "granted")  {
    //       var  options        =   data.options
    //       var  notification   =  new  Notification("Con Alejandro Fenix", options);
    //   }
    // });
  }

  function printNotificaciones(data){
    if (data.response.notificaciones!==undefined) {
      data.response.notificaciones.map((row,key)=>{
        let mensaje= {
                          body:   row.body,
                          icon:   LogoNotificacion,
                          dir :   "ltr",
                          title : row.title,
                      };
        // Functions.Socket.emit('send_notificacion',{options:mensaje});
        //console.log(mensaje);
        if  (Notification.permission  ===  "granted")  {
            var options         =   mensaje
            if (serviceWorkerRegistration.default) {
              var  notification   =   new  Notification(row.title, options);
            }else {
              serviceWorkerRegistration.sendNotification(mensaje.icon,row.title,mensaje.body,IcoNotificacion,key);
            }
        }
      })
    }
  }

  function sendNotificationNow(){
    let mensaje= {
                      body:   "Prueba Don Alejandro de las notificaciones",
                      icon:   "https://cdn.iconscout.com/icon/free/png-512/overwatch-2-569226.png",
                      dir :   "ltr",
                      title : "ltr"
                  };
    Functions.Socket.emit('send_notificacion',{options:mensaje});
  }

  useEffect(() => {
    get_notificacion()
    Functions.Socket.on('get_notificacion', get_notificacion.bind(this));
  },[])

  return  <StateContext.Provider value={{ Constants:Constants,
                                          Store:Store,
                                          Config:Config,
                                          setState:_setState,
                                          alert:alert,
                                          setAlert:setAlert,
                                          user:user,
                                          setUser:setUser}}>
              <Alert alert={alert} setAlert={setAlert} />
              {Store.get("user").token!==undefined && activarMenu?seleccionMenu("menu"):<></>}
              {/* {btnPreguntarNotificaciones?<div className="text-center"><div className="btn btn-primary" onClick={preguntarNotificaciones}>Aceptar Notificaciones</div></div>:<></>} */}
              {!btnPreguntarNotificaciones?<>
                                            <BrowserRouter>
                                              <Switch>
                                                <Route exact path={"/auth*"} render={()=><Auth/>}/>
                                                <Route exact path={"/"} render={()=><Dashboard/>}/>
                                                <Route exact path={"/usuarios/usuarios_listado"} render={()=><Usuarios/>}/>
                                                <Route exact path={"/usuarios/cambio_usuario"} render={()=><CambioUsuarios/>}/>
                                                <Route exact path={"/configuracion/equipos"} render={()=><RegistrarEquipos/>}/>
                                                <Route exact path={"/configuracion/maestro_futbol"} render={()=><Configuracion/>}/>
                                                <Route exact path={"/configuracion/tipos_usuarios_registrar"} render={()=><TipoUsuarios/>}/>
                                                <Route exact path={"/convocatoria/registro_convocatorias"} render={()=><RegistroConvocatorias/>}/>
                                                <Route exact path={"/cronogramas/cronograma"} render={()=><RegistroCronograma/>}/>
                                                <Route exact path={"/cronogramas/cronograma/add*"} render={()=><RegistroCronogramaPasoDos/>}/>
                                                <Route exact path={"/cronogramas/cronograma/microCalendario*"} render={()=><RegistroMicroCronograma/>}/>
                                                <Route exact path={"/cronogramas/reporte_entrenamientos*"} render={()=><RegistroMicroCronogramaEntrenador/>}/>
                                                <Route exact path={"/cronogramas/encuestas_atletas*"} render={()=><RegistroMicroEncuestas/>}/>
                                                <Route exact path={"/cronogramas/galeria_fotos_videos*"} render={()=><GaleriaFotosVideos/>}/>
                                                <Route exact path={"/cronogramas/notificaciones*"} render={()=><Notificaciones sendNotificationNow={sendNotificationNow}/>}/>
                                                <Route exact path={"/reportes/rpe*"} render={()=><ReportesRPE/>}/>
                                                <Route exact path={"/reportes/antropometria*"} render={()=><Antropometria/>}/>
                                                <Route exact path={"/testingHelper/copy*"} render={()=><HelperCopy/>}/>
                                                <Route exact path={"/tags/listado*"} render={()=><Tags/>}/>
                                                <Route exact path={"/tags/tag/open"} render={()=><TagsOpenFullScreen/>}/>
                                              </Switch>
                                              {Store.get("user").token!==undefined && activarMenu?seleccionMenu("footer"):<></>}
                                            </BrowserRouter></>:<></>}
          </StateContext.Provider>
}
export default App;
