import React,{useEffect,useState} from 'react';
import Store from "../helpers/Store";
import { faPlay,faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let control_cronometro  =   false
let timeActual          =   new Date();
let timeInicial;
let acumularTime        =   0;
let acumularTime2,
    ss,
    mm,
    hh;

const App=(props)=>{
  const [end, setEnd]             = useState(false);
  const [stoping, setStoping]           = useState(false);
  const [terminarPartido, setTerminarpartido]           = useState(false);
  const [play, setPlay]           = useState(false);
  const [hora, setHora]           = useState("00");
  const [minutos, setMinutos]     = useState("00");
  const [segundos, setSegundos]   = useState("00");

  useEffect(() => {
    //Store.set("cronometro",{})
    let store =  Store.get("cronometro")
    if (  (store[props.id]!==undefined &&
          store[props.id].hora!==undefined &&
          store[props.id].minutos!==undefined &&
          store[props.id].segundos!==undefined) &&
          (props.cronometro.hora_inicio!==undefined && props.cronometro.hora_inicio!=='00:00:00')) {
        if (store[props.id].play) {
          control_cronometro  =   setInterval(function(){ actualizaTiempo() }, 1000);
          setPlay(true)
        }
    }else if (props.cronometro.hora_inicio==='00:00:00' && store[props.id]!==undefined) {
      Store.set("cronometro",{[props.id]:{
            inicio: new Date(),
            hora:"00",
            minutos:"00",
            segundos:"00",
            play:false
      }})
    }
    // else {
    //   Store.set("cronometro",{[props.id]:{
    //         inicio: new Date(),
    //         hora:"00",
    //         minutos:"00",
    //         segundos:"00",
    //         play:false
    //
    //   }})
    // }
  },[props.cronometro]);

  useEffect(() => {
    if (props.cronometro.hora_final!==undefined && props.cronometro.hora_final!=='00:00:00') {
      setEnd(true)
    }
  },[props.cronometro])

  function start(){
    props.sendCronometro("inicio","00:00:00")
    control_cronometro  =   setInterval(function(){ actualizaTiempo() }, 10);
    Store.set("cronometro",{[props.id]:{
          inicio: new Date(),
          hora:"00",
          minutos:"00",
          segundos:"00",
          play:true

    }})
    setPlay(true)
  }

  function stop(){
    let tiempo_acumulado  = hora+":"+minutos+":"+segundos
    //props.sendCronometro("finalizar",tiempo_acumulado)
    setPlay(false)
    setStoping(true)
    // Store.set("cronometro",{[props.id]:{
    //       inicio: new Date(),
    //       hora:hora,
    //       minutos:minutos,
    //       segundos:segundos,
    //       play:false
    // }})
    clearInterval(control_cronometro);
    setEnd(true)
  }

  function actualizaTiempo(){
    let store =  Store.get("cronometro")
        if (store[props.id]===undefined) {
          return false;
        }
        timeActual      =   new Date();
        timeInicial     =   new Date(store[props.id].inicio);
        acumularTime    =   timeActual - timeInicial;
        acumularTime2   =   new Date(acumularTime);
        ss = acumularTime2.getSeconds();
        mm = acumularTime2.getMinutes();
        hh = acumularTime2.getHours()-19;
        if (ss < 10) {ss = "0"+ss;}
        if (mm < 10) {mm = "0"+mm;}
        if (hh < 10) {hh = "0"+hh;}
        setMinutos(mm);
        setSegundos(ss);
        setHora(hh);
  }

  function nuevoTiempo(){
    // Store.set("cronometro",{[props.id]:{
    //       inicio: new Date(),
    //       hora:"00",
    //       minutos:"00",
    //       segundos:"00",
    //       play:true
    // }})

    // setHora("00");
    // setMinutos("00");
    // setSegundos("00");

    setPlay(false);
    setStoping(false);
    props.getInit();
  }

  function FinalizarPartido(){
    let tiempo_acumulado  = hora+":"+minutos+":"+segundos
    props.sendCronometro("finalizar",tiempo_acumulado)
    setPlay(false);
    setEnd(true)
    clearInterval(control_cronometro);
    setTerminarpartido(true)
  }

  return <>
          {!terminarPartido?<>
            <div className={end?"p-1":"d-none"} >
              {stoping?<div className="btn  btn-danger mt-2 mr-1" onClick={()=>{nuevoTiempo()}}>
                Continuar reloj
              </div>:false}
              {stoping?<div className="btn  btn-dark mt-2" onClick={()=>{FinalizarPartido()}}>
                Finalizar Partido
              </div>:false}
            </div>
            <div className={!end || !stoping?"":"d-none"}>
              <div className="btn btn-block btn-dark mt-2">
                {!play?<div onClick={start}><FontAwesomeIcon icon={faPlay} /><span className="ml-1">{hora}</span>:<span>{minutos}</span>:<span>{segundos}</span></div>:<div onClick={stop}><FontAwesomeIcon icon={faStop} /><span className="ml-1">{hora}</span>:<span>{minutos}</span>:<span>{segundos}</span></div>}
              </div>
            </div>
          </>:false}
        </>
}
export default App;
