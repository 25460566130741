import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';


const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({});
  const [ma_categorias, setMa_categorias] = useState([]);
  const [ma_edad, setMa_edad] = useState([]);
  const [ma_generos, setMa_generos] = useState([]);
  const [entrenador_id, setEntrenador_id] = useState([]);
  const [ma_municipio, setMa_municipio] = useState([]);

  function onSubmit(e){
    e.preventDefault()
    let data       =  inputs
        data.id    =  Store.get("user").token
        data.cronograma = props.show.id
        data.op_macrocalendario_estructura_id  =   props.show.op_macrocalendario_estructura_id
    Functions.PostAsync("Cronograma","setCronograma",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    props.setShow({open:false,id:0,row:{}})
    props.getInit();
  }

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  function getInit(){
    if (props.show.id===0) {
      return false
    }
    let data            = inputs
        data.id         = Store.get("user").token
        data.cronograma = props.show.id
    Functions.PostAsync("Cronograma","GetCronograma",data,context,{name:"callbackGetInit",funct:callbackGetInit})
    console.log(props.show.row)
  }

  function callbackGetInit(data){
    setMa_categorias(data.response.ma_categorias);
    setMa_edad(data.response.ma_edad);
    setMa_generos(data.response.ma_generos);
    setEntrenador_id(data.response.entrenador_id);
    setMa_municipio(data.response.ma_municipio);
  }

  useEffect(() => {
    setInputs()
    setInputs({tabla:props.tabla,...props.show.row})
    getInit()
  },[props.show.open]);

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <div className="row">
                    <div className="col">
                      <select  className="form-control" onChange={onChange} name="ma_categoria_id" require>
                        <option value="">Seleccione la selección</option>
                        {
                          ma_categorias.map((row,key)=>{
                            return <option key={key} value={row.ma_categoria_id} selected={row.ma_categoria_id == props.show.row.ma_categoria_id}>{row.label}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className="col">
                      <select  className="form-control" onChange={onChange} name="ma_edad_id" require>
                        <option value="">Seleccione edad</option>
                        {
                          ma_edad.map((row,key)=>{
                            return <option key={key} value={row.ma_edad_id} selected={row.ma_edad_id == props.show.row.ma_edad_id}>{row.label}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className="col">
                      <select  className="form-control" onChange={onChange} name="ma_genero_id" require>
                        <option value="">Seleccione género</option>
                        {
                          ma_generos.map((row,key)=>{
                            return <option key={key} value={row.ma_genero_id} selected={row.ma_genero_id == props.show.row.ma_genero_id}>{row.label}</option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col">
                      <select  className="form-control" onChange={onChange} name="entrenador_id" require>
                        <option value="">Seleccione Director Técnico</option>
                        {
                          entrenador_id.map((row,key)=>{
                            return <option key={key} value={row.usuario_id} selected={row.usuario_id == props.show.row.entrenador_id}>{row.nombres}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className="col">
                      <select  className="form-control" onChange={onChange} name="ma_municipio_id" require>
                        <option value="">Seleccione Ciudad</option>
                        {
                          ma_municipio.map((row,key)=>{
                            return <option key={key} value={row.ma_municipio_id} selected={row.ma_municipio_id == props.show.row.ma_municipio_id}>{row.label}</option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
