import React,{useEffect,useState} from 'react';
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';
import Modal from './modalConvocatorias';

const App=(props)=>{

  const context = React.useContext(StateContext);

  //const [inputs, setInputs] = useState({label:"",token:""});
  const [data, setData] = useState([]);
  const [show, setShow] = useState({open:false,id:0,label:"",title:"Gestinar Maestro",row:{}});

  useEffect(() => {
    getInit()
  },[]);


  function getInit(){
    let data        = {tabla:props.tabla}
        data.token  = Store.get("user").token
    Functions.PostAsync(props.modulo,props.metodo,data,context,{name:"callbackInit",funct:callbackInit})
  }

  function callbackInit(data){
    setData(data.response.data)
  }


  return  <div className={props.className?props.className:"col-12 mb-3"}>
            <Modal show={show} setShow={setShow} {...props} getInit={getInit} />
            <div className="card">
              <div className={"card-header "+props.classHeader}>
                <div className="row">
                  <div className="col">
                    {props.title}
                  </div>
                  <div className="col-3 cursor-pointer text-right" onClick={()=>setShow({open:true,id:0,label:"",title:"Agregar "+props.title,row:[]})}>
                    Agregar
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="min-height-2000">
                  <table className="table">
                    <thead className="thead-gray">
                      <tr>
                        <th scope="col" width="20">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col" width="20">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row,k)=>{
                        return <tr key={k}>
                                <td>
                                  {k+1}
                                </td>
                                <td>
                                  {row.label}
                                </td>
                                <td className="cursor-pointer" onClick={()=>setShow({ open:true,
                                                                                      id:row.token,
                                                                                      row:row,
                                                                                      title:"Editar "+props.title,
                                                                                      })}>
                                  Editar
                                </td>
                              </tr>
                      })}
                      {data.length===0?<tr>
                              <td colSpan="3" className="text-center">
                                Sin datos a mostrar
                              </td>
                            </tr>:<></>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

}
export default App;
