import React from 'react';
import Maestro from '../../screens/maestros';


const App=(props)=>{
  return  <div className="container-fluid">
            <div className="row mb-3">
              <Maestro
                title="Selección"
                modulo="Maestros"
                metodo="tablas_maestras"
                tabla="ma_categorias"
                limit="10"
                classHeader="bg-success text-white"
              />
              <Maestro
                title="Ciclos"
                modulo="Maestros"
                metodo="tablas_maestras"
                tabla="ma_ciclos"
                limit="10"
                classHeader="bg-success text-white"
              />
              <Maestro
                title="Componetes Cargas"
                modulo="Maestros"
                metodo="tablas_maestras"
                tabla="ma_componentes_cargas"
                limit="10"
                classHeader="bg-success text-white"
              />
              <Maestro
                title="Fases"
                modulo="Maestros"
                metodo="tablas_maestras"
                tabla="ma_fases"
                limit="10"
                classHeader="bg-success text-white"
              />
              <Maestro
                title="Géneros"
                modulo="Maestros"
                metodo="tablas_maestras"
                tabla="ma_generos"
                limit="10"
                classHeader="bg-success text-white"
              />
              <Maestro
                title="Tipo entrenamiento"
                modulo="Maestros"
                metodo="tablas_maestras"
                tabla="ma_tipo_entrenamiento"
                limit="10"
                classHeader="bg-success text-white"
              />
              <Maestro
                title="Tipo Fútbol"
                modulo="Maestros"
                metodo="tablas_maestras"
                tabla="ma_tipo_futbol"
                limit="10"
                classHeader="bg-success text-white"
              />
              <Maestro
                title="Macro Calendario "
                modulo="Maestros"
                metodo="tablas_maestras"
                tabla="ma_estados_macrocalendario"
                limit="10"
                classHeader="bg-success text-white"
              />
              <Maestro
                title="Items evaluaciones "
                modulo="Maestros"
                metodo="tablas_maestras"
                tabla="ma_evaluacion"
                limit="10"
                classHeader="bg-success text-white"
              />
            </div>
          </div>
}

export default App;
