import React,{useEffect,useState} from 'react';
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';
import Modal from './modalUsuarios';
import ModalJugadores from './modalJugadores';
import ModalRelUsuariosCategorias from './modalRelUsuariosCategorias';
import { faEdit,faBasketballBall,faAddressCard,faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import queryString from 'query-string';
const queryStringParams = queryString.parse(window.location.search);

const App=(props)=>{

  const context = React.useContext(StateContext);

  //const [inputs, setInputs] = useState({label:"",token:""});
  const [data, setData] = useState({array:[]});
  const [show, setShow] = useState({open:false,id:0,label:"",title:"Gestinar Maestro",row:{}});
  const [show2, setShow2] = useState({open:false,id:0,label:"",title:"Gestinar Maestro",row:{}});

  useEffect(() => {
    getInit()
  },[]);


  function getInit(){
    let data        = {tabla:props.tabla}
        data.token  = Store.get("user").token
        data.filter = JSON.stringify({tipo_usuario_id:queryStringParams.tipo})
    Functions.PostAsync(props.modulo,props.metodo,data,context,{name:"callbackInit",funct:callbackInit})
  }

  function callbackInit(data){
    setData({array:data.response.data, ma_categorias:data.response.ma_categorias})
  }


  function onClickChangeEstatus(row){
    let data        = row
    Functions.PostAsync(props.modulo,props.metodo+"ChangeEstatus",data,context,{name:"callbackInvitacion",funct:callbackInvitacion})
  }

  function onClickInvitacion(row){
    let data        = row
    Functions.PostAsync(props.modulo,props.metodo+"Invitacion",data,context,{name:"callbackInvitacion",funct:callbackInvitacion})
  }

  function callbackInvitacion(){
    getInit()
  }

  return  <div className={props.className?props.className:"col-12 col-sm-4 mb-3"}>
            {
              show.row!==undefined &&
              show.row.tipo_usuario_id!==undefined &&
              show.row.tipo_usuario_id==="4"?<ModalJugadores show={show} setShow={setShow} {...props} getInit={getInit} />:<Modal show={show} setShow={setShow} {...props} getInit={getInit} />
            }
            <ModalRelUsuariosCategorias data={data}  setData={setData}  show={show2} setShow={setShow2} {...props} getInit={getInit} />
            <div className="card">
              <div className={"card-header "+props.classHeader}>
                <div className="row">
                  <div className="col">
                    {props.title}
                  </div>
                  {parseInt(Store.get("user").tipo_usuario_id)<=3 || parseInt(Store.get("user").tipo_usuario_id)===23 || parseInt(Store.get("user").tipo_usuario_id)===19?<>
                    <div className="col text-right cursor-pointer" onClick={()=>setShow({ open:true,
                                                                                        id:0,
                                                                                        row:{tipo_usuario_id:queryStringParams.tipo},
                                                                                        title:"Agregar "+props.title,
                                                                                        })} icon={faEdit}>
                      Agrega usuario
                    </div>
                  </>:false}
                </div>
              </div>
              <div className="card-body p-0">
                <div className="min-height-2000">
                  <table className="table">
                    <thead className="thead-gray">
                      <tr>
                        <th scope="col" width="20">#</th>
                        <th scope="col">Perfil</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Código Invitación</th>
                        <th scope="col" width="50" className="text-center">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.array.map((row,k)=>{
                        return <tr key={k}>
                                <td>
                                  {k+1}
                                </td>
                                <td>
                                  {row.perfil_string}
                                </td>
                                <td>
                                  {row.nombres} {row.apellidos}
                                </td>
                                <td>
                                  {row.codigo_invitacion}
                                </td>
                                <td width="200">
                                  <div className="row text-center">
                                    <div className="col" title="relación del perfil">
                                      <FontAwesomeIcon className="cursor-pointer" onClick={()=>setShow2({ open:true,
                                                                                                          id:row.token,
                                                                                                          row:row,
                                                                                                          title:"Relacionar con categorías ",
                                                                                                          })} icon={faBasketballBall}/>
                                    </div>
                                    <div className="col" title="Editar perfil">
                                      <FontAwesomeIcon className="cursor-pointer" onClick={()=>setShow({ open:true,
                                                                                                          id:row.token,
                                                                                                          row:row,
                                                                                                          title:"Editar "+props.title,
                                                                                                          })} icon={faEdit}/>
                                    </div>
                                    <div className="col" title="Invitación">
                                      <FontAwesomeIcon className="cursor-pointer" onClick={()=>onClickInvitacion(row)} icon={faAddressCard}/>
                                    </div>
                                    <div className="col" title={row.estatus==="1"?"Desactivar perfil":"Activar perfil"}>
                                      <FontAwesomeIcon className={row.estatus==="1"?"cursor-pointer text-success":"cursor-pointer text-danger"} onClick={()=>onClickChangeEstatus(row)} icon={faCircle}/>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                      })}
                      {data.length===0?<tr>
                              <td colSpan="3" className="text-center">
                                Sin datos a mostrar
                              </td>
                            </tr>:<></>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

}
export default App;
