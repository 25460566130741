import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import StateContext from '../helpers/ContextState'
import Logo from '../assets/images/design/logo.png';
import Store from '../helpers/Store'

const App=(props)=>{
  const context = React.useContext(StateContext);
  return  <footer>
            <div className="container-fluid footer mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-4">
                    <div>
                      <a href="#" className="text-white">Quienes somos</a>
                    </div>
                    <div>
                      <a href="#" className="text-white">Categorías</a>
                    </div>
                    <div>
                      <a href="#" className="text-white">Estadísticas</a>
                    </div>
                    <div>
                      <a href="#" className="text-white">Contactos</a>
                    </div>
                    <div>
                      <a href="#" className="text-white">Blog</a>
                    </div>
                    <div>
                      <a href="#" className="text-white">Galería</a>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div>
                      <a href="#" className="text-white">Mi cuenta</a>
                    </div>
                    <div>
                      <a className="text-white">Servicios al cliente</a>
                    </div>
                    <div>
                      <a href="#" className="text-white">300 582 1452</a>
                    </div>
                    <div>
                      <img src={Logo} alt="Logo" className="img-fluid"/>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div>
                      <a href="https://fcf.com.co/" target="_blank" className="text-white">Federación colombiana de fútbol</a>
                    </div>
                    <div>
                      <a href="https://www.fifa.com/es" target="_blank" className="text-white">FIFA</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
}

export default App;
