import React from 'react';
import icoImage from '../assets/images/design/icoImage.png';
import { Store } from "../helpers/Index";
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state   =  {
      avatar:false,
    }
  }

  handleChange=(event)=>{
    let file      = event.target.files[0];
    let file_size = event.target.files[0].size;
    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;
    let reader         =   new FileReader();
     reader.objeto     =   this;
     reader.Labels     =   this.props.Labels;

     reader.onloadend  =   function(_this) {
       let attachment  = {  avatar:reader.result,
                                file:file,
                                file_size:file_size,
                                file_name:file_name,
                                file_type:file_type
                              }
      reader.objeto.props.setImage({avatar:reader.result,file_type:file_type,file_size:file_size})
     }
     reader.readAsDataURL(file);
  }
  render() {
    return (
      <div className="p-2 border">
        <div className={(this.props.image.avatar===undefined)?"row position-relative":"d-none"}>
          <input onChange={this.handleChange} type="file" accept="image/*" name="attachment" className="inputfile position-absolute"/>
          <div className="col-2">
            <img src={icoImage} alt="PGRW"/>
          </div>
          <div className="col-9 pl-4">
            {this.props.title}
          </div>
        </div>
        {(this.props.image.avatar!==undefined)?<div className="row">
                                <div className="col-4">
                                  <img src={this.props.image.avatar} alt="PGRW" className="img-fluid"/>
                                </div>
                              </div>:''}
      </div>
    )
  }
}
export default App;
