import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';



const Items=(props)=>{

  const context = React.useContext(StateContext);
  const [reRender, setReRender] = useState(false);
  const [privilegios, setPrivilegios] = useState(false);

  function handlePrivilegeUser(e,row3){
    let data          =   {tabla:"tipo_usuarios"}
        data.token    =   Store.get("user").token
        data.boolean  =   JSON.stringify(e.target.checked)
        data.data     =   JSON.stringify(row3)
        data.id       =   props.show.id
    Functions.PostAsync("Maestros","privilegeUsers",data,context,{name:"callback",funct:callback})

    if (e.target.getAttribute("checked")!==null) {
      e.target.removeAttribute("checked")
    }
  }

  function callback(data){
    props.getInit()
    setPrivilegios(JSON.parse(data.response.data.privilegios));
  }

  useEffect(() => {
    setPrivilegios(JSON.parse(props.show.row.privilegios));
  },[]);

  return Object.entries(props.Constants.Modulos).map((row,key)=>{

      if (row[0]!=='auth' && row[0]!=='cerrar_sesion' && !reRender) {
        console.log(row);
        if (row[1]===undefined) {
          return <></>
        }
        return  <div className="row mb-3" key={key}>
                  <div className="col-2 bg-secondary">
                    {row[1].label}
                    <div><input type="checkbox" onClick={(e)=>{handlePrivilegeUser(e,row); }}/></div>
                  </div>
                  { row[1].items!==undefined?row[1].items.map((row2,key2)=>{
                      return  <div key={key2} className="col-3">
                                {
                                  Object.entries(row2).map((row3,key3)=>{
                                    if (row3[1].label!==undefined) {
                                      let p = row3[0]
                                      let checking  = false
                                      return <div key={key3} className="text-center">
                                                {row3[1].label}
                                                <div className="text-center">
                                                  {privilegios[p]!==undefined?<input checked="checked" type="checkbox" onClick={(e)=>{handlePrivilegeUser(e,row3); }}/>:<input type="checkbox" onClick={(e)=>{handlePrivilegeUser(e,row3); }}/>}
                                                </div>
                                              </div>
                                    }else {
                                      return <></>
                                    }
                                  })
                                }
                              </div>
                    }):<></>
                  }
                </div>
      }else {
        return <div key={key}></div>
      }
    })
}

const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({label:"",token:"",tabla:""});

  function onSubmit(e){
    e.preventDefault()
    let data       = inputs
        data.id    = Store.get("user").token
    Functions.PostAsync(props.modulo,props.metodo+"Add",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    if (data.response.close) {
      props.getInit()
      props.setShow({open:false,id:0,row:{}})
    }
  }

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  useEffect(() => {
    setInputs()
    setInputs({tabla:props.tabla,...props.show.row})
  },[props.show]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <input onChange={onChange} name="label" className="form-control" type="text" placeholder={'Nombre...'} defaultValue={props.show && props.show.row.label?props.show.row.label:''} require/>
                </div>
                {
                  Store.get("user").tipo_usuario_id>=2?<div>usuasrio</div>:<Items {...context} {...props}/>
                }
                <div className="mt-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
