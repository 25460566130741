import React,{useEffect,useState} from 'react';
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';
import Modal from './modalCronograma';
import MacroCronograma from './modalMacroCronograma';
import { faFile, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const App=(props)=>{

  const context = React.useContext(StateContext);

  //const [inputs, setInputs] = useState({label:"",token:""});
  const [data, setData] = useState([]);
  const [show, setShow] = useState({open:false,id:0,label:"",title:"Gestinar Maestro",row:{}});
  const [show2, setShow2] = useState({open:false,id:0,label:"",title:"Gestinar Maestro",row:{}});


  useEffect(() => {
    getInit()
  },[]);


  function getInit(){
    let data        = {tabla:props.tabla}
        data.token  = Store.get("user").token
    Functions.PostAsync(props.modulo,props.metodo,data,context,{name:"callbackInit",funct:callbackInit})
  }

  function callbackInit(data){
    setData(data.response.data)
  }

  return  <div className={props.className?props.className:"col-12 mb-3"}>
            <Modal show={show} setShow={setShow} {...props} getInit={getInit} />
            {show2?<MacroCronograma show={show2} setShow={setShow2} {...props} getInit={getInit} />:<></>}
            <div className="card">
              <div className={"card-header "+props.classHeader}>
                <div className="row">
                  <div className="col">
                    {props.title}
                  </div>
                  <div className="col-3 cursor-pointer text-right" onClick={()=>setShow({open:true,id:0,label:"",title:"Agregar "+props.title,row:[]})}>
                    Agregar
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="min-height-2000">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-gray">
                        <tr>
                          <th scope="col" width="20">#</th>
                          <th scope="col">Nombre</th>
                          <th scope="col" width="200" className="text-center">Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row,k)=>{
                          return <tr key={k}>
                                  <td>
                                    {k+1}
                                  </td>
                                  <td>
                                    {row.label}
                                  </td>
                                  <td className="cursor-pointer text-center">
                                    <div className="row justify-content-center">
                                      <div className="col-auto" title="Ingresar información">
                                        <a href={context.Config.ConfigAppUrl+"cronogramas/cronograma/add?id="+row.token}>
                                          <FontAwesomeIcon icon={faFile}/>
                                        </a>
                                      </div>
                                      <div className="col-auto" title="Editar Macrocalendario">
                                        <FontAwesomeIcon className="cursor-pointer" onClick={()=>setShow({ open:true,
                                                                                                            id:row.token,
                                                                                                            row:row,
                                                                                                            title:"Editar "+props.title,
                                                                                                            })} icon={faEdit}/>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                        })}
                        {data.length===0?<tr>
                                <td colSpan="3" className="text-center">
                                  Sin datos a mostrar
                                </td>
                              </tr>:<></>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

}
export default App;
