import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';
import UploadPic from '../screens/UploadPic'
import UploadVideo from '../screens/uploadVideo'


const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({entrenamiento_realizado:"",foto:"",video:""});
  const [image, setImage] = useState({});
  const [video, setVideo] = useState({file:false});

  function onSubmit(e){
    e.preventDefault()
    let data                  = props.show.row
        data.id               = Store.get("user").token
        data.attachment       = JSON.stringify(image)
        data.entrenamiento_realizado=inputs.entrenamiento_realizado
        data.attachmentVideo  = JSON.stringify(video)
        //return console.log(image,video);
    Functions.PostAsync("Cronograma","setCheckList",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    if (data.response.close) {
      props.setShow({open:false,id:0,row:{}})
      props.getInit()
    }
  }

  function onChange(e){
    setInputs({entrenamiento_realizado:e.target.value})
  }

  useEffect(() => {
    setInputs()
    setInputs({...props.show.row})
  },[props.show]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <div className="row mb-3">
                    <div className="col">
                      SI <input type="radio" name="entrenamiento_realizado" className="form-controls" value="SI" onChange={onChange}  />
                    </div>
                    <div className="col">
                      NO <input type="radio" name="entrenamiento_realizado" className="form-controls" value="NO" onChange={onChange}  />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <UploadPic setImage={setImage} image={image} title={"Seleccione la foto"}/>
                    </div>
                    <div className="col">
                      <UploadVideo setVideo={setVideo} video={video} title={"Seleccione el Video"}/>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
