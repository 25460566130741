import React from 'react';
import Maestro from '../../screens/tableUsuarios';


const App=(props)=>{
  return  <div className="container">
            <div className="row mb-3">
              <Maestro
                title="Lista de usuarios"
                modulo="Maestros"
                metodo="tablas_usuarios"
                tabla="usuarios"
                limit="10"
                asignar={true}
                classHeader="bg-success text-white"
                className="col-12 col-sm-12"
              />
            </div>
          </div>
}

export default App;
