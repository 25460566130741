import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';

const sexo=[{label:"Masculino",value:"Masculino"},{label:"Femenino",value:"Femenino"}]

const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({label:"",token:"",tabla:""});

  function onSubmit(e){
    e.preventDefault()
    let data       = inputs
        data.id    = Store.get("user").token
    Functions.PostAsync(props.modulo,props.metodo+"Add",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    if (data.response.close) {
      props.setShow({open:false,id:0,row:{}})
      props.getInit()
    }
  }

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  useEffect(() => {
    setInputs()
    setInputs({tabla:props.tabla,...props.show.row})

  },[props.show.open]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit} className="row">
                <div className="col-12  col-sm-4 mb-3">
                  <input onChange={onChange} name="nombres" className="form-control" type="text" placeholder={'Nombres...'} defaultValue={props.show && props.show.row.nombres?props.show.row.nombres:''} require/>
                </div>
                <div className="col-12  col-sm-4 mb-3">
                  <input onChange={onChange} name="apellidos" className="form-control" type="text" placeholder={'Apellidos...'} defaultValue={props.show && props.show.row.apellidos?props.show.row.apellidos:''} require/>
                </div>
                <div className="col-12  col-sm-4 mb-3">
                  <input onChange={onChange} name="email" className="form-control" type="text" placeholder={'Email...'} defaultValue={props.show && props.show.row.email?props.show.row.email:''} require/>
                </div>
                <div className="col-12  col-sm-12 mb-3">
                  <input onChange={onChange} name="direccion" className="form-control" type="text" placeholder={'Dirección completa (Ciudad y Departamento)'} defaultValue={props.show && props.show.row.direccion?props.show.row.direccion:''}/>
                </div>
                <div className="col-12  col-sm-3 mb-3">
                  <input onChange={onChange} name="cedula" className="form-control" type="text" placeholder={'Identificación'} defaultValue={props.show && props.show.row.cedula?props.show.row.cedula:''}/>
                </div>
                <div className="col-12  col-sm-3 mb-3">
                  <select name="sexo" className="form-control" onChange={onChange} required defaultValue={props.show && props.show.row.sexo?props.show.row.sexo:''}>
                    <option value="">Género</option>
                    {
                      sexo.map((row,key)=>{
                        return <option key={key} value={row.value}>{row.label}</option>
                      })
                    }
                  </select>
                </div>
                <div className="col-12  col-sm-3 mb-3">
                  <input onChange={onChange} name="telefono_fijo" className="form-control" type="text" placeholder={'Teléfono fijo'} defaultValue={props.show && props.show.row.telefono_fijo?props.show.row.telefono_fijo:''}/>
                </div>
                <div className="col-12  col-sm-3 mb-3">
                  <input onChange={onChange} name="celular" className="form-control" type="text" placeholder={'Celular'} defaultValue={props.show && props.show.row.celular?props.show.row.celular:''}/>
                </div>
                <div className="col-12  col-sm-4 mb-3">
                  <input onChange={onChange} name="lugar_nacimiento" className="form-control" type="text" placeholder={'Lugar de nacimiento'} defaultValue={props.show && props.show.row.lugar_nacimiento?props.show.row.lugar_nacimiento:''}/>
                </div>
                <div className="col-12  col-sm-4 mb-3">
                  <input onChange={onChange} name="fecha_nacimiento" className="form-control" type="date" placeholder={'Fecha de nacimiento'} defaultValue={props.show && props.show.row.fecha_nacimiento?props.show.row.fecha_nacimiento:''}/>
                </div>
                <div className="col-12  col-sm-4 mb-3">
                  <input onChange={onChange} name="posicion" className="form-control" type="text" placeholder={'Posición'} defaultValue={props.show && props.show.row.posicion?props.show.row.posicion:''}/>
                </div>
                <div className="col-12  col-sm-4 mb-3">
                  <input onChange={onChange} name="perfil" className="form-control" type="text" placeholder={'Perfil'} defaultValue={props.show && props.show.row.perfil?props.show.row.perfil:''}/>
                </div>
                <div className="col-12  col-sm-4 mb-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
