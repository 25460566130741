import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';


const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({label:"",token:"",tabla:""});

  function onSubmit(e){
    e.preventDefault()
    let data       = inputs
        data.id    = Store.get("user").token
    Functions.PostAsync(props.modulo,props.metodo+"Add",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    if (data.response.close) {
      props.setShow({open:false,id:0,row:{}})
      props.getInit()
    }
  }

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  useEffect(() => {
    setInputs()
    setInputs({tabla:props.tabla,...props.show.row})

  },[props.show.open]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <input onChange={onChange} name="nombres" className="form-control" type="text" placeholder={'Nombres...'} defaultValue={props.show && props.show.row.nombres?props.show.row.nombres:''} require/>
                </div>
                <div className="mb-3">
                  <input onChange={onChange} name="apellidos" className="form-control" type="text" placeholder={'Apellidos...'} defaultValue={props.show && props.show.row.apellidos?props.show.row.apellidos:''} require/>
                </div>
                <div className="mb-3">
                  <input onChange={onChange} name="email" className="form-control" type="text" placeholder={'Email...'} defaultValue={props.show && props.show.row.email?props.show.row.email:''} require/>
                </div>
                <div className="mt-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
