import React,{useEffect,useState} from 'react';
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';
import Modal from './modalMicroCalendarioSelect';
import ModalCheckList from './modalMicroCalendarioCheckList';
import { faCalendarCheck,faTrash,faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const App=(props)=>{

  const context = React.useContext(StateContext);
  const [data4, setData4] = useState({});
  const [data3, setData3] = useState({ ma_tipo_entrenamiento:[]});
  const [data2, setData2] = useState({});
  const [show, setShow] = useState({open:false,id:0,label:"",title:"Gestinar Maestro",row:{}});
  const [showCheckList, setShowCheckList] = useState({open:false,id:0,label:"",title:"Gestinar Maestro",row:{}});

  useEffect(() => {
    setData2({
      op_macrocalendario_estructura_calendario_id:props.data2.op_macrocalendario_estructura_calendario_id,
      op_macrocalendario_estructura_id:props.data2.op_macrocalendario_estructura_id,
      op_macrocalendario_id:props.data2.op_macrocalendario_id,
    })
    getInit()
  },[]);


  function getInit(){
    let data              =   data2
        data.token        =   Store.get("user").token
        data.ma_fases_id  =   props.ma_fases_id
        data.op_macrocalendario_estructura_calendario_id  = props.data2.op_macrocalendario_estructura_calendario_id
        data.op_macrocalendario_estructura_id             = props.data2.op_macrocalendario_estructura_id
        data.op_macrocalendario_id                        = props.data2.op_macrocalendario_id
    Functions.PostAsync("Cronograma","getMicrocronogramaItems",data,context,{name:"callbackInit",funct:callbackInit})
  }

  function callbackInit(data){
    setData3({ma_tipo_entrenamiento:data.response.ma_tipo_entrenamiento})
    setData4(data.response.data)
  }

  function onClickAdd(row){
    let data        = data2
        data.fecha  = row.value;
        setShow({open:true,data:data2,id:row.value,label:"",title:"Agregar item",row:data3})
  }

  function handlerDelete(id){
    let data              =   data2
        data.token        =   Store.get("user").token
        data.micro_id     =   id
        
    if(window.confirm("¿Está seguro de que desea eliminar esta entrada?")){
      Functions.PostAsync("Cronograma","delMicrocronogramaItems",data,context,{name:"callbackDelete",funct:callbackDelete})
    }
  }

  function callbackDelete(data){
    getInit()
  }

  function openCheckList(row){
    setShowCheckList({open:true,id:0,label:"",title:"CheckList del entrenamiento",row:{}})
  }



  return  <div className={props.className?props.className:"col-12 mb-3"}>
            <Modal  show={show} setShow={setShow} {...props} getInit={getInit} />
            <ModalCheckList  show={showCheckList} setShow={setShowCheckList} {...props} getInit={getInit} />
            <div className="card">
              <div className={"card-header "+props.classHeader}>
                <div className="row">
                  <div className="col">
                    {props.title}
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="min-height-2000">
                  <div  className="table-responsive">
                    <table className="table">
                      <thead className="thead-gray">
                        <tr>
                          {props.data.fechas.map((row,k)=>{
                            if (props.ma_fases_id==='1') {
                              return <th key={k} className="text-center border-right"><a href={context.Config.ConfigApirest+"Cronograma/Exportar?m=exportar_microcalendario&id="+props.id+"&fecha="+row.value_format} className="text-center btn btn-primary btn-xs text-white">{row.label}</a><div className="text-center">{row.value_format}</div><div className="text-center "> <div className="btn btn-link" onClick={()=>onClickAdd(row)}> + </div></div></th>
                            }else {
                              return <th key={k} className="text-center border-right"><div className="text-center">{row.label}</div><div className="text-center">{row.value_format}</div><div className="text-center "> <div className="btn btn-link " onClick={()=>onClickAdd(row)}> + </div></div></th>
                            }
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {props.data.fechas.map((row,k)=>{
                            return <td key={k} className="border-right">{ (data4[row.value]!==undefined)?data4[row.value].map((row2,key2)=>{
                              return  <div key={key2} className="text-center bg-gray mb-2 ">
                                        <div>{row2.label}</div>
                                        <div>Desde: <b>{row2.hora_desde}</b></div>
                                        <div>hasta: <b>{row2.hora_hasta}</b></div>
                                        <div className="p-2"> {row2.video==="NO" && row2.foto==="NO"?<FontAwesomeIcon onClick={()=>openCheckList(row2)} className="cursor-pointer" icon={faCalendarCheck}/>:<FontAwesomeIcon className="cursor-pointer" icon={faSearch}/>}
                                        <FontAwesomeIcon className="ml-3 cursor-pointer" icon={faTrash} onClick={()=>handlerDelete(row2.token)}/>
                                        </div>
                                      </div>
                            }):"" }</td>
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

}
export default App;
