import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';


const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({});
  const [data, setData] = useState({microcalendario:[]});

  function onSubmit(e){
    e.preventDefault()
    let data       =  inputs
        data.id    =  Store.get("user").token
        data.op_macrocalendario_estructura_id  =   0
    Functions.PostAsync("Cronograma","setCronograma",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    props.setShow({open:false,id:0,row:{}})
    props.getInit();
  }

  function getInit(){
    let data                        = {}
        data.token                  = Store.get("user").token
        data.op_microcalendario_id  = props.show.row.op_microcalendario_id
        data.fecha  = props.show.row.fecha
    Functions.PostAsync("Cronograma","GetCronogramaAsistencia",data,context,{name:"callbackCronogramaAsistencia",funct:callbackCronogramaAsistencia})
  }

  function callbackCronogramaAsistencia(data){
    setData(data.response)
  }

  useEffect(() => {
    getInit()
  },[props.show.open]);

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  function handleClick(e,row){
    let data_                        =  data
        data_.token                  =   Store.get("user").token
        data_.op_microcalendario_id  =   data.op_microcalendario_id
        data_.usuario_id             =   row.usuario_id
        data_.label                  =   e.target.value
        data_.fecha                  =   data.fecha
        console.log(data_);
    Functions.PostAsync("Cronograma","setAsistencia",data_,context,{name:"callbackSetAsistencia",funct:callbackSetAsistencia})
  }

  function callbackSetAsistencia(data){
    getInit();
  }

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {data.microcalendario.map((row,k)=>{
              return <div className="row text-center pb-3 border-bottom mb-2" key={k}>
                        <div className="col-12 mb-2 text-left">
                          <b>{row.nombres} {row.apellidos}</b>
                        </div>
                        <div className="col-3 ">
                          Asistió
                          <br/>
                          <input defaultChecked={row.asistencia && row.asistencia==="ASISTENCIA"?true:false } type="radio" name={"asistencia"+k} value="ASISTENCIA" onClick={(e)=>handleClick(e,row)}/>
                        </div>
                        <div className="col-3 ">
                          Retraso
                          <br/>
                          <input defaultChecked={row.asistencia && row.asistencia==="RETRASO"?true:false } type="radio" name={"asistencia"+k} value="RETRASO" onClick={(e)=>handleClick(e,row)} />
                        </div>
                        <div className="col-3 ">
                          Excusa
                          <br/>
                          <input defaultChecked={row.asistencia && row.asistencia==="EXCUSA"?true:false } type="radio" name={"asistencia"+k} value="EXCUSA" onClick={(e)=>handleClick(e,row)} />
                        </div>
                        <div className="col-3 ">
                          faltó
                          <br/>
                          <input defaultChecked={row.asistencia && row.asistencia==="NO ASISTENCIA"?true:false } type="radio" name={"asistencia"+k} value="NO ASISTENCIA" onClick={(e)=>handleClick(e,row)} />
                        </div>
                      </div>
              })
            }
            </Modal.Body>
          </Modal>
}

export default App;
