export default{
  Prefijo:"/pwa",
  ConfigAppUrl : (document.location.hostname==='localhost')?'http://localhost:3000/': (process.env.NODE_ENV==='development')?'https://pwa.mi-seleccion.com/':'https://pwa.mi-seleccion.com/',
  ConfigSocketUrl : (document.location.hostname==='localhost')?'https://socket.miseleccion.laf.com.co:3841/':(process.env.NODE_ENV==='development')?'https://socket.miseleccion.laf.com.co:3841/':'https://socket.miseleccion.laf.com.co:3841/',
  ConfigNotifications:process.env.REACT_APP_URL_NOTIFICATIONS,
  ConfigApirest   : (document.location.hostname==='localhost')?'https://localhost/apiMiSeleccion/ApiRest/':(process.env.NODE_ENV==='development')?'https://miseleccion.laf.com.co/backend/ApiRest/':'https://miseleccion.laf.com.co/backend/ApiRest/',
  Title:'MI SELECION',
  Alt:'Mi selección',
  db:"Mi selección",
  logo:require("../assets/images/design/logo.png"),
  error:require("../assets/images/design/error-licencia.gif"),
}