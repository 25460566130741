import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';


const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({});
  const [data, setData] = useState({data:[]});
  const [evaluacionAlumno, setEvaluacionAlumno] = useState({});

  function onSubmit(e){
    e.preventDefault()
    let data       =  inputs
        data.id    =  Store.get("user").token
        data.op_macrocalendario_estructura_id  =   0
    Functions.PostAsync("Cronograma","setCronograma",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    props.setShow({open:false,id:0,row:{}})
    props.getInit();
  }

  function getInit(){
    let data                        = {tabla:"ma_evaluacion"}
        data.token                  = Store.get("user").token
        data.op_microcalendario_id  = props.show.row.op_microcalendario_id
        data.fecha                  = props.show.row.fecha
        data.antes_despues          = props.show.id.antes_despues
    Functions.PostAsync("Maestros","tablas_maestras",data,context,{name:"callbackCronogramaAsistencia",funct:callbackCronogramaAsistencia})

    let data___                 =   {}
        data___.token           =   Store.get("user").token
        data___.atleta_id       =   props.show.id.usuario_id
        data___.op_macrocalendario_estructura_id   =   props.show.id.fecha
        data___.antes_despues   =   props.show.id.antes_despues
        data___.fecha           =   props.show.id.fecha_string
    Functions.PostAsync("Cronograma","getEvaluacionAlumno",data___,context,{name:"callbackGetEvaluacionAlumno",funct:callbackGetEvaluacionAlumno})
  }

  function callbackGetEvaluacionAlumno(data){
    setEvaluacionAlumno(data.response.microcalendario_evaluacion)
  }

  function callbackCronogramaAsistencia(data){
    setData(data.response)
  }

  useEffect(() => {
    getInit()
  },[props.show.open]);

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  function callbackSetAsistencia(data){
    getInit();
  }

  function selectData(e,row){
    let data__                        =  {}
        data__.token                  =   Store.get("user").token
        data__.value                  =   e.target.value
        data__.atleta_id                          =   props.show.id.usuario_id
        data__.op_macrocalendario_estructura_id   =   props.show.id.fecha
        data__.antes_despues      =   props.show.id.antes_despues
        data__.ma_evaluacion_id   =   row.ma_evaluacion_id
        data__.fecha              =   props.show.id.fecha_string
        //return console.log(data__);
    Functions.PostAsync("Cronograma","setEvaluacionDespues",data__,context,{name:"callbackSetAsistencia",funct:callbackSetAsistencia})
  }

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="alert alert-primary">Cuál es la valoración del esfuerzo de la sesión de entrenamiento.</div>
              {data.data.map((row,key)=>{
                return  <div className="row text-center mb-2 border-bottom pb-3" key={key}>
                          <div className="col-12 col-sm-11 text-left">
                            <b>{row.label}</b>
                            <br/>
                            {row.descripcion}
                          </div>
                          <div className="col-1 col-sm-1">
                            <input checked={evaluacionAlumno[row.ma_evaluacion_id]!==undefined} onClick={(e)=>{selectData(e,row)}} name={"opcion"} type="radio" value={row.ma_evaluacion_id} />
                          </div>
                        </div>
              })}
            </Modal.Body>
          </Modal>
}

export default App;
