import React,{useState} from 'react';
import icoImage from '../assets/images/design/icoImage.png';
//import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
//import StateContext from '../helpers/ContextState';
import Config from "../helpers/Config";
import axios from 'axios';

const App=(props)=>{
  //const context = React.useContext(StateContext);
  const [uploading, setUploading] = useState(false);
  //const [data, setData] = useState({});
  const [progressBar, setProgressBar] = useState(0);
  //const [selectedFile, setSelectedFile] = useState(null);

  // function onUpload2(new_data){
  //   let data_upload                  = new_data
  //       data_upload.token            = Store.get("user").token
  //       //return console.log(data_upload);
  //   Functions.PostAsync("Cronograma","uploadVideo",data_upload,context,{name:"callbackUpload",funct:callbackUpload})
  // }

  function onUpload(selectedFile){
	const formData =   new FormData();
	const config   =   {
    										headers: {
    											"content-type": "multipart/form-data"
    										},
    										onUploadProgress: progressEvent => {
    											var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    											setProgressBar(percentCompleted)
    										},
    										onDownloadProgress: e => {

    										}
    									};

	// Update the formData object
	formData.append(
		"userfile",
		selectedFile,
		selectedFile.name
	);

		formData.append ("token", Store.get("user").token);
		formData.append ("PUBLIC_KEY", process.env.REACT_APP_PUBLIC_KEY);
	  formData.append ("REACT_APP_PRIVATE_KEY", process.env.REACT_APP_PRIVATE_KEY);

		axios.post(Config.ConfigApirest+"Cronograma/UploadVideoEntrenador", formData, config).then(function(response) {
      if (response.data.response.error) {
        setUploading(false)
      }else {
        props.setVideo(response.data.response)
        setUploading("ready")
      }
  	}).catch(function(error) {
	    console.log(error);
	  });

	};

  // function callbackUpload(data){
  //   console.log(data.response);
  // }

  function handleChange(event){
    onUpload(event.target.files[0])
    setUploading(true)
  }

  return <div className="p-2 border">
            <div className={uploading==='ready' && (  props.video!==undefined &&
                                                      props.video.file!==undefined &&
                                                      props.video.file.fullurl!==undefined) ?"row position-relative":"d-none"}>
              <div className="col-12">
                <a href={props.video.file.fullurl} target="_blank">Abrir Video</a>
              </div>
            </div>
            <div className={uploading && uploading!=="ready"?"row position-relative":"d-none"}>
              <div className="col-12">
                {progressBar}%
                <div className="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar" aria-valuenow={progressBar}
                        aria-valuemin="0"
                        aria-valuemax="100" style={{width:"100%"}}></div>
                </div>
              </div>
            </div>
            <div className={!uploading?"row position-relative":"d-none"}>
              <input onChange={handleChange} type="file" accept="video/*" name="attachmentVideo" className="inputfile position-absolute"/>
              <div className="col-2">
                <img src={icoImage} alt="PGRW"/>
              </div>
              <div className="col-9 pl-4">
                {props.title}
              </div>
            </div>
          </div>
}

export default App;
