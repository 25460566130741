import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';


const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({label:"",token:"",tabla:""});

  function onSubmit(e){
    e.preventDefault()
    let data       = inputs
        data.id    = Store.get("user").token
    Functions.PostAsync("Tags","AddPartida",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    if (data.response.close) {
      props.setShow({open:false,id:0,row:{}})
      props.getInit()
    }
  }

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  useEffect(() => {
    setInputs()
    setInputs({tabla:props.tabla,...props.show.row})

  },[props.show.open]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-12 col-sm-4 mb-3">
                      <select name="modalidad" className="form-control" onChange={onChange} required>
                        <option value="">Modalidad</option>
                        <option value="Fútbol">Fútbol</option>
                        <option value="Fútbol sala">Fútbol sala</option>
                        <option value="Fútbol playa">Fútbol playa</option>
                      </select>
                    </div>
                    <div className="col-12 col-sm-4 mb-3">
                      <select name="categoria" className="form-control" onChange={onChange} required>
                        <option value="">Categoría</option>
                        <option>Prejuvenil</option>
                        <option>Infantil</option>
                        <option>Juvenil</option>
                        <option>Sub 13 (2008)</option>
                        <option>Sub 15 (2006)</option>
                        <option>Sub 17 (2004)</option>
                        <option>Sub 19 (2003)</option>
                        <option>Sub 20 (2002)</option>
                        <option>Sub 21 (2001)</option>
                        <option>Sub 23 (1999)</option>
                      </select>
                    </div>
                    <div className="col-12 col-sm-4 mb-3">
                      <select name="rama" className="form-control" onChange={onChange} required>
                        <option value="">Rama</option>
                        <option value="Masculino">Masculino</option>
                        <option value="Femenino">Femenino</option>
                      </select>
                    </div>
                    <div className="col-12 col-sm-4 mb-3">
                      <input  onChange={onChange}
                              name="tiempo"
                              className="form-control"
                              type="time"
                              placeholder={'Hora del partido'}
                              defaultValue={''} require/>
                    </div>
                    <div className="col-12 col-sm-8 mb-3">
                      <input  onChange={onChange}
                              name="label"
                              className="form-control"
                              type="text"
                              placeholder={'Fase...'}
                              defaultValue={''} require/>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <input  onChange={onChange}
                              name="fecha"
                              className="form-control"
                              type="date"
                              placeholder={'Fecha...'}
                              defaultValue={''} require/>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <input  onChange={onChange}
                              name="lugar"
                              className="form-control"
                              type="text"
                              placeholder={'Lugar o cancha'}
                              defaultValue={''} require/>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <select name="tipo_de_partido" className="form-control" onChange={onChange} required>
                        <option value="">Tipo de partida</option>
                        <option value="amistoso">Amistoso</option>
                        <option value="competencia">Competencia</option>
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <input  onChange={onChange}
                              name="rival"
                              className="form-control"
                              type="text"
                              placeholder={'Rival'}
                              defaultValue={''} require/>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
