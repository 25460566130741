import React from 'react';
import { BrowserRouter, Route, Switch  } from "react-router-dom";
import Login from "./login";
import Recover from "./recover";
import RecoverPass from "./recoverPass";
import Register from "./register";
import Store from '../../helpers/Store'


const App=()=>{

  if (Store.get("user").tipo_usuario_id===undefined) {
    document.body.style.backgroundColor = "#000"
  }

  return  <div className="mt-5">
            <BrowserRouter>
              <Switch>
                <Route exact path={"/auth/login"} render={()=><Login/>}/>
                <Route exact path={"/auth/recover"} render={()=><Recover/>}/>
                <Route exact path={"/auth/recoverPass*"} render={()=><RecoverPass/>}/>
                <Route exact path={"/auth/register*"} render={()=><Register/>}/>
              </Switch>
            </BrowserRouter>
          </div>  

}

export default App;
