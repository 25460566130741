import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';


const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs]         =   useState({label:"",token:"",tabla:""});

  function onSubmit(e){
    e.preventDefault()
    let data                          =   inputs
        data.id                       =   Store.get("user").token
        data.props                    =   JSON.stringify(props)
        data.ma_fases_id              =   props.ma_fases_id
        data.director_tecnico_id      =   props.inputs.director_tecnico_id
        data.arbrito_id               =   props.inputs.arbrito_id
        data.preparador_arquero_id    =   props.inputs.preparador_arquero_id
        data.kinesiologo_id           =   props.inputs.kinesiologo_id
        //data.ma_componentes_cargas_id =   props.inputs.ma_componentes_cargas_id
        data.inputs                   =   JSON.stringify(props.inputs)
    Functions.PostAsync("Cronograma","AddEntrenamientoCalendario",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    if (data.response.close) {
      props.setShow({open:false,id:0,row:{}})
      props.getInit()
      // setInputs({
      //   ma_fases_id:props.ma_fases_id,
      //   director_tecnico_id:props.inputs.director_tecnico_id,
      //   arbrito_id:props.inputs.arbrito_id,
      //   preparador_arquero_id:props.inputs.preparador_arquero_id,
      //   kinesiologo_id:props.inputs.kinesiologo_id,
      //   ma_componentes_cargas_id:props.inputs.ma_componentes_cargas_id,
      // })
    }
  }

  function onChange(e){
    let inputs_ = inputs
        inputs_[e.target.name]  =   e.target.value
    setInputs(inputs_)
    console.log(inputs);
  }

  useEffect(() => {
    console.log(props.show.row);
    setInputs()
    setInputs({tabla:props.tabla,...props.show.row})
  },[props.show.open]);

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <div className="row">
                    <div className="col">
                      <select name="ma_componentes_cargas_id"  className="form-control" onChange={onChange} required>
                        <option value="">Componente de carga</option>
                        {
                          (props.show.row.ma_tipo_entrenamiento!==undefined)?props.show.row.ma_tipo_entrenamiento.map((row,key)=>{
                            return <option key={key} value={row.ma_tipo_entrenamiento_id}>{row.label}</option>
                          }):<></>
                        }

                      </select>
                    </div>
                    <div className="col">
                      <input onChange={onChange} name="hora_desde" className="form-control" type="time" require="true"/>
                    </div>
                    <div className="col">
                      <input onChange={onChange} name="hora_hasta" className="form-control" type="time" require="true"/>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
