import React,{useState,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Functions from "../helpers/Functions";
import Store from "../helpers/Store";
import StateContext from '../helpers/ContextState';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const App=(props)=>{

  const context = React.useContext(StateContext);
  const [inputs, setInputs] = useState({label:"",token:"",tabla:""});
  const [data, setData] = useState([]);

  function onSubmit(e){
    e.preventDefault()
    let data    = {}
        data.ma_categoria_id  =   inputs.ma_categoria_id
        data.usuario_id       =   props.show.row.usuario_id
        data.id               =   Store.get("user").token
    Functions.PostAsync("User","RelacionarUsuariosSeleccion",data,context,{name:"callbackSubmit",funct:callbackSubmit})
  }

  function callbackSubmit(data){
    if (data.response.close) {
      props.setShow({open:false,id:0,row:{}})
      props.getInit()
    }
  }

  function onChange(e){
    let input                = inputs
        input[e.target.name] = e.target.value
        setInputs(input)
  }

  useEffect(() => {
    setInputs()
    setInputs({tabla:props.tabla,...props.show.row})
    getInit()
  },[props.show.open]);

  function getInit(){
    let data    = {}
        data.usuario_id       =   props.show.row.usuario_id
        data.id               =   Store.get("user").token
    Functions.PostAsync("User","GetRelacionUsuariosSeleccion",data,context,{name:"callbackGetInit",funct:callbackGetInit})
  }

  function callbackGetInit(data){
    setData(data.response.data)
  }

  return <Modal
            show={props.show.open}
            onHide={() => props.setShow({open:false,id:0,row:{}})}
            dialogClassName="modal-90w"
            size="lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {props.show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <select name="ma_categoria_id"  className="form-control" onChange={onChange} required>
                    <option value="">Selección</option>
                    {
                      (props.data.ma_categorias!==undefined)?props.data.ma_categorias.map((row,key)=>{
                        return <option key={key} value={row.ma_categoria_id}>{row.label}</option>
                      }):<></>
                    }

                  </select>
                </div>
                {data.map((row,key)=>{
                    return <div className="row" key={key}>
                            <div className="col">
                              <FontAwesomeIcon className="cursor-pointer mr-2" icon={faTrash}/>
                              {row.label}
                            </div>
                          </div>
                })}
                <div className="mt-3">
                  <button className="btn btn-primary btn-block" type="submit">Guardar</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
}

export default App;
