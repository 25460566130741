export default{
  Development : 'ProgramandoWeb',
  Title:'Mi Selección',
  LogoSm:'./images/ico-md.png',
  View:'Title',
  Modulos:{
    auth:{
      register:{
        url:"auth/register",
        ico:"",
      },
      login:{
        url:"auth/login",
        ico:"",
      },
      recover:{
        url:"auth/recover",
        ico:"",
      },
      recoverPass:{
        url:"auth/recoverPass",
        ico:"",
      },
      luisadelgado:{
        url:"luisadelgado",
        ico:"",
      },
    },
    usuarios:{
      label:"Usuarios",
      url:"usuarios/usuarios_listado",
      dropdown:true,
      items:[{
              todos:{
                public:true,
                label:"Todos",
                url:"usuarios/usuarios_listado",
                ico:"",
              },
              registrar:{
                public:false,
                url:"usuarios/usuarios_registrar",
                ico:"",
              },
              editar:{
                public:false,
                url:"usuarios/usuarios_editar",
                ico:"",
              },
              eliminar:{
                public:false,
                url:"usuarios/usuarios_eliminar",
                ico:"",
              }
            },{
              Jugadores:{
                public:true,
                label:"Jugadores",
                url:"usuarios/usuarios_listado?tipo=4",
                ico:"",
              }
            },{
              Entrenador:{
                public:true,
                label:"Preparador Físico",
                url:"usuarios/usuarios_listado?tipo=3",
                ico:"",
              }
            },{
              Administrador:{
                public:true,
                label:"Administrador",
                url:"usuarios/usuarios_listado?tipo=2",
                ico:"",
              }
            },{
              Directortecnico:{
                public:true,
                label:"Director técnico",
                url:"usuarios/usuarios_listado?tipo=19",
                ico:"",
              }
            },{
              Arbitro:{
                public:true,
                label:"Analista de Rendimiento",
                url:"usuarios/usuarios_listado?tipo=20",
                ico:"",
              }
            },{
              PreparadorArquero:{
                public:true,
                label:"Preparador de arquero",
                url:"usuarios/usuarios_listado?tipo=21",
                ico:"",
              },
            },{
              KinesiOlogo:{
                public:true,
                label:"Kinesiólogo",
                url:"usuarios/usuarios_listado?tipo=22",
                ico:"",
              },
            },{
              Asistente:{
                public:true,
                label:"Asistente técnico",
                url:"usuarios/usuarios_listado?tipo=23",
                ico:"",
              },
            }
          ]
    },
    configuracion:{
      label:"Configuración",
      url:"configuracion/configurar",
      dropdown:true,
      items:[{
          registrar:{
            public:false,
            url:"usuarios/tipos_usuarios_registrar",
            ico:"",
          },
        },
        {
          tipos_usuarios:{
              public:true,
              label:"Tipo de usuarios",
              url:"configuracion/tipos_usuarios_registrar",
              ico:"",
            },
        },
        // {
        //   /*
        //     este parámetro se utiliza para tomar la cantidad de semanas
        //     para las estadísticas del RC en el excel de nombre
        //     CONTROL GABBETT
        //   */
        //   semanas_estadisticas:{
        //       public:true,
        //       label:"Semanas para estadísticas",
        //       url:"configuracion/semanas_estadisticas",
        //       ico:"",
        //     },
        // },
        {
          op_macrocalendario_estructura_calendario:{
              public:false,
              label:"Copia data estructura calendario",
              url:"testingHelper/copy?id=op_macrocalendario_estructura_calendario",
              ico:"",
            },
        },
        {
          maestro_futbol:{
            public:true,
            label:"Tablas Maestras",
            url:"configuracion/maestro_futbol",
            ico:"",
          },
        }
      ]
    },
    reporte:{
      label:"Reporte",
      url:"reportes/reporte",
      dropdown:true,
      items:[{
          antropometria:{
            public:true,
            label:"Antropometría",
            url:"reportes/antropometria",
            ico:"",
          },
        },{
          entrenamientos:{
            public:true,
            label:"RPE",
            url:"reportes/rpe",
            ico:"",
          },
        },{
          tags:{
          public:true,
          label:"Análisis de las competencias",
          url:"tags/listado",
          ico:"",},
        },{
          galeria_entrenamientos:{
              public:true,
              label:"Galería fotos y videos",
              /*este perfil llena los datos del microcalendario*/
              url:"cronogramas/galeria_fotos_videos",
              ico:"",
            },
        }
      ]
    },
    cronograma:{
      label:"Planificación",
      url:"cronogramas/cronograma",
      dropdown:true,
      items:[{
          registrar:{
            public:true,
            label:"Macrocalendario",
            url:"cronogramas/cronograma",
            ico:"",
          },
        },{
          equipos:{
              public:true,
              label:"Reportar entrenamiento",
              /*este perfil llena los datos del microcalendario*/
              url:"cronogramas/reporte_entrenamientos",
              ico:"",
            },
        },{
          encuestas:{
              public:true,
              label:"Encuestas Atletas",
              /*este perfil llena los datos del microcalendario*/
              url:"cronogramas/encuestas_atletas",
              ico:"",
            },
        },{
          notificaciones:{
              public:true,
              label:"Notificaciones",
              url:"cronogramas/notificaciones",
              ico:"",
            },
        },
      ]
    },
    cambio_usuario:{
      label:"cambio de Usuario",
      url:"usuarios/cambio_usuario",
    },
    cerrar_sesion:{
      label:"Cerrar Sesión",
      url:"Cerrar",
    },
  }
}
